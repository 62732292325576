window.debounce = function (func, wait) {
  var timeout;

  return function () {
    var context = this,
      args = arguments;

    var executeFunction = function () {
      func.apply(context, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(executeFunction, wait);
  };
};
window.link_to_obj = function (id, id_obj) {
  $.ajax({
    type: "PUT",
    url: "/pages/objectives/" + id_obj + "/linked_objective",
    data: {
      linked_obj_id: id,
    },
    success: function (data) {
      window.location.reload();
    },
  });
};

window.unlink_obj = function (id_obj) {
  $.ajax({
    type: "PUT",
    url: "/pages/objectives/" + id_obj + "/unlinked_objective",
    data: {},
    success: function (data) {
      window.location.reload();
    },
  });
};

window.popoverTotal = (selector, title, placement) => {
  selector
    .popover({
      title: title,
      html: true,
      placement: placement,
      trigger: "manual",
    })
    .on("mouseenter", function () {
      setTimeout(() => {
        $(this).popover("show");
        $(".popover-total").on("mouseleave", () => {
          $(this).popover("hide");
        });
      }, 100);
    })
    .on("mouseleave", function () {
      setTimeout(() => {
        if (!$(".popover-total:hover").length) $(this).popover("hide");
      }, 100);
    });
};

window.showTooltips = (e) => {
  $(e).find("img").tooltip("show");
};

window.hideTooltip = (e) => {
  $(e).find("img").tooltip("hide");
};

window.checkValid = (value, min, max) => {
  $(".error-big-input").remove();
  if (max > min) {
    if (value < min || value > max) {
      $("#input-range")
        .closest(".card-body")
        .append(
          `<span class='font-size-13px pull-right text-danger error-big-input'>Error: Value not in range</span>`,
        );
      return false;
    } else return true;
  } else if (max < min) {
    if (value > min || value < max) {
      $("#input-range")
        .closest(".card-body")
        .append(
          `<span class='font-size-13px pull-right text-danger error-big-input'>Error: Value not in range</span>`,
        );
      return false;
    } else return true;
  } else return true;
};

window.checkDecimalValue = (real_value) => {
  $(".error-big-input").remove();
  if (Number.isInteger(parseFloat(real_value)) && real_value.charAt(0) != ".") {
    return true;
  } else {
    $("#input-range")
      .closest(".card-body")
      .append(
        `<span class='font-size-13px pull-right text-danger error-big-input'>Error: Please use whole/round numbers only.</span>`,
      );
    return false;
  }
};

window.afterUpdateBigProgressBar = (msg, status = "success") => {
  let value_status, alertTxt;
  if (status == "success") value_status = "bg-green-100";
  else value_status = "bg-red";
  alertTxt = `<div class="flash_messages_wrapper">
                <div class="position-relative alert m-auto border-0 text-center w-100 color-white ${value_status}" id="alert_dismissible">
                  <span id="flash_success" class="position-relative font-size-16px">
                    ${msg}
                  </span>
                </div>
              </div>`;
  $(".flash_messages_wrapper").remove();
  $(".container").append(alertTxt);
  $("#alert_dismissible").slideDown(500);
  setTimeout(function () {
    $("#alert_dismissible").slideUp(500);
  }, 3000);
};

// set variable bg //
var not_start_bg = "bg-image-gray-400 bg-gray-400-opacity gray";
var at_risk_bg = "bg-image-red bg-red-opacity";
var behind_bg = "bg-image-yellow bg-yellow-opacity";
var on_track_bg = "bg-image-green-100 bg-green-100-opacity success";
var back_burner_bg = "bg-blue bg-blue-opacity";

// set variable color //
var not_start_color = "color-gray-400";
var at_risk_color = "color-red";
var behind_color = "color-yellow";
var on_track_color = "color-green-100";
var back_burner_color = "color-blue";

window.setColorOnScroll = (selector, percent, white_timeline, place_in_timeline = null) => {
  var percent     = Math.round(percent);
  var behind      = white_timeline - percent <= 25 && percent > 0 && percent < white_timeline;
  var at_risk     = percent + 25 < white_timeline;
  var on_track    = percent >= 70 || percent >= white_timeline;
  var not_start   = value == ((revert == "true" && max) || min);
  var back_burner = selector.attr("progress_status") == "back_burner";

  let clr_1, clr_2, clr_3, clr_4;
  if (selector.length > 0) {
    if (
      selector.attr("class").includes("score_value") ||
      selector.attr("class").includes("spacing-value") ||
      selector.attr("class").includes("goal-unit-m") ||
      selector.attr("class").includes("score-beside-option")
    ) {
      clr_1 = not_start_color;
      clr_2 = at_risk_color;
      clr_3 = behind_color;
      clr_4 = on_track_color;
    } else {
      clr_1 = not_start_bg;
      clr_2 = at_risk_bg;
      clr_3 = behind_bg;
      clr_4 = on_track_bg;
    }
  }
  if (back_burner) {
    return selector.removeClass(`${clr_1} ${clr_2} ${clr_4} ${clr_3}`);
  } else if (white_timeline >= 101 || white_timeline <= -1) {
    if ((!at_risk && percent > 0) || (!behind && percent > 0 && place_in_timeline == "future_time"))
      return selector.removeClass(`${clr_1} ${clr_2} ${clr_3}`).addClass(`${clr_4}`);
    if (not_start && place_in_timeline == "future_time")
      return selector.removeClass(`${clr_2} ${clr_4} ${clr_3}`).addClass(`${clr_1}`);
    if (not_start) return selector.removeClass(`${clr_2} ${clr_4} ${clr_3}`).addClass(`${clr_1}`);
    if (on_track) return selector.removeClass(`${clr_1} ${clr_2} ${clr_3}`).addClass(`${clr_4}`);
    if (behind) return selector.removeClass(`${clr_1} ${clr_4} ${clr_2}`).addClass(`${clr_3}`);
    if (at_risk) return selector.removeClass(`${clr_1} ${clr_4} ${clr_3}`).addClass(`${clr_2}`);
  } else {
    if (place_in_timeline == "present_time") {
      if (not_start) return selector.removeClass(`${clr_2} ${clr_4} ${clr_3}`).addClass(`${clr_1}`);
      if (on_track) return selector.removeClass(`${clr_1} ${clr_2} ${clr_3}`).addClass(`${clr_4}`);
      if (behind) return selector.removeClass(`${clr_1} ${clr_4} ${clr_2}`).addClass(`${clr_3}`);
      if (at_risk) return selector.removeClass(`${clr_1} ${clr_4} ${clr_3}`).addClass(`${clr_2}`);
    }
  }
};

window.onChangeSearchTeams = debounce(function (e, page_controller) {
  var team_id = e.parents(".modal").find("#team_selected").val();
  if (page_controller == "departments") {
    $.ajax({
      type: "GET",
      url: "objectives/search_team",
      data: {
        id: window.location.pathname.match(/(\d+)/)[0],
        search: e.val(),
        team_selected: team_id,
        type_modal: "temporary",
      },
    });
  } else {
    $.ajax({
      type: "GET",
      url: "/pages/objectives/search_team",
      data: {
        search: e.val(),
        team_selected: team_id,
        type_modal:
          e.parents(".card").find("#labelCompanyKrListTeams").length == 0
            ? "temporary"
            : "immediate",
      },
    });
  }
}, 500);

window.starting_del_obj = (that, id) => {
  var params = get_page_need_updated();
  var remote = that.dataset["remote"];
  $.ajax({
    url: `/pages/objectives/${id}`,
    type: "DELETE",
    data: {
      page: that.dataset["page"],
      controller_name: params[0],
      user_id: params[1],
      obj_id: params[2],
      type_team_okrs: params[3],
      detail_page_id: params[4],
      year: urlParam("year"),
      quarter: urlParam("quarter"),
      remote: remote,
    },
    dataType: "script",
    beforeSend: function () {
      xstart();
      $(`.modal#alertDeleteOKR_${id}`).modal("hide");
      openLoading();
    },
    success: function (data) {
      $("#modal_loading").modal("hide");
      xend();
    },
  });
};

window.starting_del_obj_new = (that) => {
  let params, id, obj_current, previous_tab, controller, remote, type_teams;
  params = get_page_need_updated();
  id = that.dataset["id"];
  obj_current = that.dataset["data-id-current"];
  previous_tab = that.dataset["previous_tab"];
  controller = that.dataset["controller"];
  remote = that.dataset["remote"] === "true";
  // check my teams or all teams in team okrs
  if ($('.filter-team-okrs input[name="check"]:checked').length > 0) {
    type_teams =
      $('.filter-team-okrs input[name="check"]:checked').val() == "0" ? "my_teams" : "all_teams";
  }
  remote = !(remote && id == obj_current);

  $.ajax({
    url: `/pages/objectives/${id}`,
    type: "DELETE",
    data: {
      controller_name: params[0],
      user_id: params[1],
      obj_id: params[2],
      type_team_okrs: params[3],
      detail_page_id: params[4],
      year: urlParam("year"),
      quarter: urlParam("quarter"),
      remote: remote,
      previous_tab: previous_tab,
      controller_obj: controller,
      type_teams: type_teams,
      annualy_normaly_choice: urlParam("annualy_normaly_choice"),
    },
    dataType: "script",
    beforeSend: function () {
      xstart();
      $(`.modal#alertDeleteOKR_new`).modal("hide");
      openLoading();
    },
    success: function (data) {
      $("#modal_loading").modal("hide");
      if (params[0] == "objectives") {
        if ($("#bodyKeyResult ul").length == 0) {
          $(".has_obj").addClass("no_obj").removeClass("has_obj");
          $(".card-none-kr").addClass("padding-y-18px").removeClass("padding-y-0px");
        }
      }
      xend();
    },
  });
};

window.disableEnter = function (e) {
  if (Event.keyCode == 13) {
    Event.preventDefault();
  }
};

window.highlightSearch = function (e) {
  $(e).css("box-shadow", "none");
  $(e)
    .parent()
    .css("box-shadow", "0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 0.35rem rgba(44, 130, 201, 1)");
};

// get params url, exp: $.urlParam('year')
window.urlParam = (name) => {
  var urlString = window.location.href.replaceAll("&amp;", "&")
  var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(urlString);
  if (results == null) {
    return null;
  }
  return decodeURI(results[1]) || 0;
};

// add more kr in page
window.addMoreKr = (kr) => {
  var obj_parent_width, deep, obj_parent, new_kr_deep, new_kr;
  obj_parent = $(kr).parent().prev().find(".okr-name-width");
  if (obj_parent.hasClass("width-1")) obj_parent_width = "width-1";
  else if (obj_parent.hasClass("width-2")) obj_parent_width = "width-2";
  else obj_parent_width = "width-full-size";
  deep = obj_parent.find(".deep-size").data("deep");
  new_kr_deep = deep + 1;
  new_kr = kr.prev();
  $(new_kr)
    .find(".okr-name-width")
    .removeClass("width-1 width-2 width-full-size")
    .addClass(obj_parent_width);
  new_kr
    .find(".deep-size")
    .data("deep", new_kr_deep)
    .removeClass(`kr-deep-1 kr-deep-2 kr-deep-3 kr-deep-4`)
    .addClass(`kr-deep-${new_kr_deep}`);
};

// remove obj /kr after delete obj / kr
window.removeObj = (id) => {
  var objs = $(`div.nested-fields[data-index="okr-${id}"`);
  objs.each(function (index) {
    var obj = $(this);
    var parent = obj.parents("ul").first();
    if (parent.attr("id") == "bodyKeyResult") {
      obj.parents("li").first().remove();
    } else if (parent.find("li.parent_li").length > 1 || parent.find(".parent-first").length == 1) {
      var ul_remove = parent.parents("ul").first();
      if (ul_remove.length != 0) {
        if (ul_remove.is(":last-child")) {
          var ul_length = ul_remove.parent().children("ul").length - 1;
          ul_remove
            .parent()
            .children(`ul:nth-child(${ul_length})`)
            .removeClass("tree-hierarchy-before");
        }
        // only 1 obj / kr
        if (ul_remove.parent().children().length == 1) {
          ul_remove
            .parents(".title-hierarchy-line")
            .first()
            .find(".updown-unlinked-icon-before")
            .removeClass("updown-unlinked-icon-before");
        }
        ul_remove.remove();
      } else {
        console.log("Remove the last KR in Ob detail");
        parent.remove();
        if ($(".card-body-add-kr").length > 0) {
          add_kr_btn();
        }
      }
    }
  });
};

window.add_kr_btn = () => {
  let params, id;
  params = get_page_need_updated();
  id = params[2];
  $.ajax({
    url: `/pages/objectives/${id}/add_kr_btn`,
    type: "GET",
    data: {},
    dataType: "script",
  });
};

// update color of the progress bar after delete / move back burner / move out back burner / add a kr
window.update_color_progress_bar = (id, progress_status, bg_image, bg_opacity, color, status) => {
  var inputs = $(`.input_range_${id} input`);
  inputs.attr("progress_status", progress_status);
  inputs
    .removeClass(`bg-image-green-100 bg-image-yellow bg-image-gray-400 bg-image-red bg-image-blue`)
    .addClass(bg_image);
  inputs
    .removeClass(
      `bg-green-100-opacity bg-yellow-opacity bg-gray-400-opacity bg-red-opacity bg-blue-opacity`,
    )
    .addClass(bg_opacity);
  inputs.removeClass(`gray danger warning success primary`).addClass(status);

  var texts = $(`.input_range_${id} .value-process-bar`).children();
  texts
    .removeClass(`color-green-100 color-yellow color-gray-400 color-red color-blue`)
    .addClass(color);
  texts.attr("progress_status", progress_status);

  var scores = $(`.score-beside-option[data-id="${id}"]`);
  scores
    .removeClass(`color-green-100 color-yellow color-gray-400 color-red color-blue`)
    .addClass(color);
  scores.attr("progress_status", progress_status);
};

// update progress bar after delete / move back burner / move out back burner / add a kr / update progress bar
window.reload_progress_bar = (input_range) => {
  input_range
    .on("input", function () {
      var selector,
        score_value,
        text_value,
        goalUnit,
        statusDot,
        scoreOption,
        value,
        revert,
        min,
        max,
        score,
        percent;
      selector = $(this);
      score_value = selector
        .parents(".okr-progress-width")
        .next(".okr-score-width")
        .find(".score_value");
      text_value = selector.parents("#input-range").find(".score_value");
      goalUnit = selector.parents("#input-range").find(".goal-unit-m");
      statusDot = selector
        .parents(".okr-progress-width")
        .prev(".okr-status-width")
        .find(".status-dot");
      scoreOption = selector.parents(".nested-fields").find(".score-beside-option");
      value = parseInt(selector.val());
      revert = selector.attr("revert");
      min = parseInt(selector.attr("min"));
      max = parseInt(selector.attr("max"));

      if (revert == "true") {
        value = max - value + min;
        score = (max - value) / (max - min);
        percent = score * 100;
      } else {
        score = (value - min) / (max - min);
        percent = score * 100;
      }

      // set background //
      selector.css("backgroundSize", `${percent}% 100%`);
      // set text value
      text_value.text(value.toLocaleString());
      // set score value //
      score_value.text((Math.round(score * 10) / 10).toFixed(1));
      scoreOption.text((Math.round(score * 10) / 10).toFixed(1));
      // set color //
      setColorOnScroll(selector, percent, white_timeline);
      setColorOnScroll(text_value, percent, white_timeline);
      setColorOnScroll(goalUnit, percent, white_timeline);
      setColorOnScroll(statusDot, percent, white_timeline);
      setColorOnScroll(score_value, percent, white_timeline);
      setColorOnScroll(scoreOption, percent, white_timeline);
    })
    .trigger("input");
};

// get controller_name to update info detail
window.get_page_need_updated = () => {
  let controller_name, user_id, obj_id, type_okrs, detail_id;
  if (window.location.pathname.includes("companies")) {
    controller_name = "companies";
  } else if (window.location.pathname.includes("team_okrs")) {
    controller_name = "team_okrs";
    type_okrs = $('.filter-team-okrs input[name="check"]:checked').val();
  } else if (window.location.pathname.includes("my_okrs")) {
    controller_name = "my_okrs";
  } else if (window.location.pathname.includes("teams/")) {
    if (!!window.location.pathname.match(/(\d+)/))
      detail_id = window.location.pathname.match(/(\d+)/)[0];
    controller_name = "teams";
  } else if (window.location.pathname.includes("departments/")) {
    controller_name = "departments";
    if (!!window.location.pathname.match(/(\d+)/))
      detail_id = window.location.pathname.match(/(\d+)/)[0];
  } else if (window.location.pathname.includes("user_okrs/")) {
    controller_name = "user_okrs";
    if (!!window.location.pathname.match(/(\d+)/)) {
      user_id = window.location.pathname.match(/(\d+)/)[0];
      detail_id = window.location.pathname.match(/(\d+)/)[0];
    }
  } else if (window.location.pathname.includes("objectives/")) {
    controller_name = "objectives";
    if (!!window.location.pathname.match(/(\d+)/))
      obj_id = window.location.pathname.match(/(\d+)/)[0];
  }

  return [controller_name, user_id, obj_id, type_okrs, detail_id];
};

window.showCompanyKrTeamRow = (e) => {
  $("#modalImmediateSearchTeam").modal("show");
  $("#labelImmediateSearchTeam").attr("row_kr", e.attr("kr_id"));
  let team_id_option = e.attr("team_id");

  $("#inputCompanyKrListTeams").val("");
  $.ajax({
    type: "GET",
    url: "/pages/objectives/search_immediate_team",
    data: {
      search: "",
      team_selected: null,
      type_modal: "immediate",
    },
    success: function (data) {
      $("#modalImmediateSearchTeam input#team_selected").val(team_id_option);
      $("#modalImmediateSearchTeam").find(".icon-checked").not(".d-none").addClass("d-none");
      if (!!team_id_option) {
        $("#modalImmediateSearchTeam li[teamId='" + team_id_option + "']")
          .find("#icon-checked")
          .removeClass("d-none");
      } else {
        $("#modalImmediateSearchTeam li[teamId='0']").find("#icon-checked").removeClass("d-none");
      }
    },
  });
};

window.changeTeamInCompanyKrRow = (e) => {
  let dept_ids, team_ids, params, kr_id, team_id, old_team_id;
  dept_ids = [].slice.call($(".dept-cluster")).map(function (ele) {
    return $(ele).attr("dept-id");
  });
  team_ids = [].slice.call($(".team-cluster")).map(function (ele) {
    return $(ele).attr("team-id");
  });
  console.log(dept_ids, "list id Departments");
  console.log(team_ids, "list id Teams");
  params = get_page_need_updated();
  kr_id = $("#labelImmediateSearchTeam").attr("row_kr");
  team_id = parseInt(e.attr("teamid"));
  old_team_id = $(".icon-checked").not(".d-none").parents(".list-group-item-action").attr("teamid");

  if (!!!kr_id) {
    if (team_id == old_team_id || (team_id == 0 && old_team_id == 0)) {
      $("#modalImmediateSearchTeam").modal("hide");
    } else {
      $.ajax({
        url: "/pages/objectives/update_team_comp_kr",
        data: {
          id: params[2],
          chosen_team_id: team_id,
          controller_name: params[0],
          user_id: params[1],
          obj_id: params[2],
          type_team_okrs: params[3],
          dept_ids: dept_ids,
          team_ids: team_ids,
          detail_page_id: params[4],
          redirect_page: "objective_show",
          quarter: urlParam("quarter"),
          year: urlParam("year"),
          annualy_normaly_choice: urlParam("annualy_normaly_choice"),
        },
        method: "PUT",
        dataType: "script",
        beforeSend: function () {
          $("#modalImmediateSearchTeam").modal("hide");
          openLoading();
        },
        success: function (data) {
          $("#modal_loading").modal("hide");
        },
      });
    }
  } else {
    if (team_id == old_team_id || (team_id == 0 && old_team_id == 0) || !!!kr_id) {
      $("#modalImmediateSearchTeam").modal("hide");
    } else {
      $.ajax({
        url: "/pages/objectives/update_team_comp_kr",
        data: {
          id: kr_id,
          chosen_team_id: team_id,
          controller_name: params[0],
          user_id: params[1],
          obj_id: params[2],
          type_team_okrs: params[3],
          dept_ids: dept_ids,
          team_ids: team_ids,
          detail_page_id: params[4],
          quarter: urlParam("quarter"),
          year: urlParam("year"),
          annualy_normaly_choice: urlParam("annualy_normaly_choice"),
        },
        method: "PUT",
        dataType: "script",
        beforeSend: function () {
          $("#modalImmediateSearchTeam").modal("hide");
          openLoading();
        },
        success: function (data) {
          $("#modal_loading").modal("hide");
        },
      });
    }
  }
};

window.trigger_change_team_kr_com = (id, exist) => {
  var team = $(`#team-${id}`);
  if (exist == "true") {
    team
      .parents(".team-cluster")
      .find(".updown-unlinked-icon")
      .addClass("updown-unlinked-icon-before");
    team
      .parents(".team-cluster")
      .children()
      .last()
      .removeClass("padding-left-84px color-gray-200")
      .addClass("p-0");
  } else {
    team
      .parents(".team-cluster")
      .find(".updown-unlinked-icon")
      .removeClass("updown-unlinked-icon-before");
    team
      .parents(".team-cluster")
      .children()
      .last()
      .addClass("padding-left-84px color-gray-200")
      .removeClass("p-0");
    team.parents(".team-cluster").children().last().html(`No Team OKRs`);
  }
};

window.load_flash_message = (msg) => {
  $(".flash_messages_wrapper").remove();
  $("#alert_dismissible").remove();
  $("body").append(msg);
};

window.toggleBackBurner = (e) => {
  let $url, objId, is_back_burner, params, type_teams;
  $(e).text("Please wait...");
  objId = parseInt($(e).attr("name_obj_id"));
  is_back_burner = $(e).attr("back_burner");
  params = get_page_need_updated();
  // check my teams or all teams in team okrs
  if ($('.filter-team-okrs input[name="check"]:checked').length > 0) {
    type_teams =
      $('.filter-team-okrs input[name="check"]:checked').val() == "0" ? "my_teams" : "all_teams";
  }

  if (is_back_burner == "true") $url = `/pages/objectives/${objId}/move_out_of_back_burner`;
  else $url = `/pages/objectives/${objId}/change_to_back_burner`;

  $.ajax({
    url: $url,
    type: "PUT",
    data: {
      changed_objective_id: objId,
      controller_name: params[0],
      user_id: params[1],
      obj_id: params[2],
      type_team_okrs: params[3],
      detail_page_id: params[4],
      year: urlParam("year"),
      quarter: urlParam("quarter"),
      type_teams: type_teams,
      annualy_normaly_choice: urlParam("annualy_normaly_choice"),
    },
    dataType: "script",
    success: function (data) {
      $("#modal_loading").modal("hide");
      $(e).text("Continue");
    },
    beforeSend: function () {
      $(e).parents(`.modal`).modal("hide");
      openLoading();
    },
  });
};

window.showModalBurnerAlert = (e) => {
  var modal;
  var obj_id = e.attr("name_obj_id");
  var parent_li = $(e).parents("li");
  if (!!!parent_li) modal = parent_li.first().find(`.modalBurnerAlert_${obj_id}`);
  else modal = $(`.modalBurnerAlert_${obj_id}`);
  modal.show();
  modal.modal({ backdrop: true });
};

window.showModalBurnerAlert_new = (e) => {
  var obj_id = e.attr("name_obj_id");
  var back_burner = e.attr("back_burner");
  var object_type = e.attr("obj_type");
  $(`.modalBurnerAlert .btn-back-burner`).attr("name_obj_id", obj_id);
  $(`.modalBurnerAlert .btn-back-burner`).attr("back_burner", back_burner);
  $(`.modalBurnerAlert .content`).html(content_modal_back_burner(object_type, back_burner));
  $(`.modalBurnerAlert`).show().modal({ backdrop: true });
};

window.content_modal_back_burner = (object_type, back_burner) => {
  var data;
  if (object_type == "main") {
    if (back_burner == "true")
      data = `<p class="m-0 text-center">You’re moving this Objective out of the back burner.</p>
      <p class="mx-0 my-2 text-center">Doing so will make the Objective, along with all its underlying Key Results “live” again. Its progress will also be computed back in any associated OKR progress reports.</p>`;
    else
      data = `<p class="m-0 text-center">Moving an Objective to the back burner is like putting a “pause” on it, without deleting it altogether.</p>
      <p class="mx-0 my-2 text-center">Once in the back burner, all Key Results under this Objective will be put to the back burner too. The Objective’s progress will also not be computed in any associated OKR progress reports.</p>`;
  } else {
    if (back_burner == "true")
      data = `<p class="m-0 text-center">You’re moving this Key Result out of the back burner. Doing so will make it “live” again.</p>
      <p class="mx-0 my-3 text-center">Its progress will also be computed back in the Objective’s overall progress %.</p>`;
    else
      data = `<p class="m-0 text-center">Moving a Key Result to the back burner is like putting a “pause” on it, without deleting it altogether.</p>
      <p class="mx-0 my-3 text-center">Once in the back burner, its progress will not be computed in the Objective’s overall progress %.</p>`;
  }
  return data;
};

window.deleteOKRModalRow = (e) => {
  var modal;
  var obj_id = e.attr("obj_id");
  var parent_li = $(e).parents("li");
  if (!!!parent_li) modal = $(e).parents("li").first().find(`#alertDeleteOKR_${obj_id}`);
  else modal = $(`#alertDeleteOKR_${obj_id}`);
  modal.show();
  modal.modal({ backdrop: true });
};

window.deleteOKRModalRow_new = (e) => {
  var obj_id = e.attr("obj_id");
  var obj_type = e.attr("obj_type");
  $("#alertDeleteOKR_new").show().modal({ backdrop: true });
  $("#alertDeleteOKR_new a").attr("data-id", obj_id);
  var desc;
  if (obj_type == "main") {
    desc =
      "You’re about to delete an Objective. All data associated with this Objective including all its underlying Key Results will be deleted.";
    $("#alertDeleteOKR_new .sub-delete-description").text("You cannot undo this step.");
  } else {
    desc =
      "You’re about to delete a Key Result. All data associated with it will be deleted. You cannot undo this step.";
    $("#alertDeleteOKR_new .sub-delete-description").text("");
  }
  $("#alertDeleteOKR_new .delete-description").html(desc);
};

window.convertToObjective = (e) => {
  var obj_id = e.attr("obj_id");
  var parent_li = $(e).parents("li");
  if (!!!parent_li)
    var modalView = $(e).parents("li").first().find(`#modalChangeObjective_${obj_id}`);
  else var modalView = $(`#modalChangeObjective_${obj_id}`);
  modalView.show();
  modalView.modal({ backdrop: true });
};

window.convertToObjective_new = (e) => {
  var obj_id = e.attr("obj_id");
  $("#modalChangeObjective a.btn-change-obj").attr("obj_id", obj_id);
  $(`#modalChangeObjective`).show().modal({ backdrop: true });
};

window.callbackBackburnerObj = (id, out_of_burner, move_to_burner) => {
  // change more option
  var option = $(
    `div.dropdown-menu-objective[aria-labelledby="dropdown_menu_${id}"] a.back_burner`,
  );
  var option_obj_detail = $(
    `div.dropdown-menu[aria-labelledby="dropdown_menu_${id}"] a.back_burner`,
  );
  if (progress_status == "back_burner") {
    option.html(out_of_burner);
    option.attr("back_burner", "true");
    option_obj_detail.html(out_of_burner);
  } else {
    option.html(move_to_burner);
    option_obj_detail.html(move_to_burner);
    option.attr("back_burner", "false");
  }
};

window.openOKRs = (id) => {
  localStorage.setItem("collapse_okr", JSON.stringify(id || []));
};

window.create_kr_new = (id, that, obi_id_current, controller_name, action_name) => {
  openOKRs(id);
  let obj_id,
    type,
    form,
    kr,
    id_ele,
    data_form,
    arr_kr,
    type_teams,
    data_form_a_kr,
    child_deep,
    list_parents,
    params;
  form = $(that).closest("form");
  kr = $(that).closest(".nested-fields");
  id_ele = kr.find(".objective_childrens_name input").attr("name").match(/(\d+)/)[0];
  data_form = $(".edit_objective").serialize();
  arr_kr = data_form
    .split("&")
    .filter(
      (item) =>
        !(item.includes("objective%5Bchildrens_attributes%5D%5B") && !item.includes(id_ele)),
    );
  params = get_page_need_updated();

  if (
    controller_name == "objectives" &&
    action_name == "show" &&
    !kr.hasClass("kr-web-application")
  ) {
    obj_id = obi_id_current;
    type = "objectives";
  } else {
    obj_id = form.attr("id").match(/(\d+)/)[0];
    type = controller_name;
    list_parents = [];
    // update score parent
    $(`.nested-fields[data-index="okr-${obj_id}"]`)
      .parents(".parent_li")
      .map(function (index) {
        list_parents.push(
          $(this).find("span .nested-fields").first().data("index").replace("okr-", ""),
        );
      });
    arr_kr.push(`list_parents=${list_parents.toString()}`);
  }
  if (type == "objectives") {
    arr_kr.push(`obi_id_current=${obi_id_current}`);
  }
  if (type == "user_okrs") {
    arr_kr.push(`user_id=${params[1]}`);
  }
  // get deep kr
  child_deep = $(`form#edit_objective_${obj_id}`).parents("#bodyKeyResult").length + 1;
  arr_kr.push(`child_deep=${child_deep}`);
  arr_kr.push(`type=${type}`);
  arr_kr.push(`ob_details_id=${params[2]}`);

  // check my teams or all teams in team okrs
  if ($('.filter-team-okrs input[name="check"]:checked').length > 0) {
    type_teams =
      $('.filter-team-okrs input[name="check"]:checked').val() == "0" ? "my_teams" : "all_teams";
    arr_kr.push(`type_teams=${type_teams}`);
  }
  // get current quarter and year
  arr_kr.push(`quarter=${urlParam("quarter")}`);
  arr_kr.push(`year=${urlParam("year")}`);
  arr_kr.push(`annualy_normaly_choice=${urlParam("annualy_normaly_choice")}`);

  if (params[4]) {
    arr_kr.push(`detail_page_id=${params[4]}`);
  }

  arr_kr.push(`type_page=${params[0]}`);

  data_form_a_kr = arr_kr.join("&");

  if (check_value_form_kr(kr, "one")) {
    $.ajax({
      url: `/pages/objectives/${obj_id}/update_a_okr`,
      type: "PUT",
      data: data_form_a_kr,
      dataType: "script",
      beforeSend: function () {
        openLoading();
      },
      success: function (data) {
        if (type == "objective_show") kr.remove();
        else
          $(`.nested-fields[data-index="${kr.attr("data-index")}"]`)
            .find(".remove_fields")
            .trigger("click");
        $("#modal_loading").modal("hide");
      },
    });
  }
};

window.check_value_form_kr = (that, type) => {
  var form;
  if (type == "one") form = that;
  else form = $("#" + that);
  if (
    form.find(" .objective_childrens_name input").val() == "" ||
    form.find(" .objective_childrens_owner_id select").val() == "" ||
    form.find(" .objective_childrens_name input").val().length > 100
  ) {
    form.find(".error-msg").removeClass("d-none").addClass("d-flex");
    if (form.find(".objective_childrens_name input").val() == "") {
      form
        .find(".objective_childrens_name input")
        .addClass("is-invalid")
        .attr("style", "border: 1px solid red !important");
      form.find(".kr-box .error-name").html("Please fill in your key result.");
    } else if (form.find(" .objective_childrens_name input").val().length > 100) {
      form
        .find(".objective_childrens_name input")
        .addClass("is-invalid")
        .attr("style", "border: 1px solid red !important");
      form
        .find(".kr-box .error-name")
        .html("Key result description must be less than 100 characters.");
    } else {
      form.find(" .objective_childrens_name input").removeClass("is-invalid").css("border", "none");
      form.find(" .kr-box .error-name").html("");
    }

    if (form.find(" .objective_childrens_owner_id select").val() == "") {
      form.find(" .objective_childrens_owner_id select").addClass("is-invalid");

      if (form.find(" .objective_childrens_name input").val() != "") {
        form.find(" .kr-box .error-owner").removeClass("offset-2").addClass("offset-10");
      } else form.find(" .kr-box .error-owner").removeClass("offset-10").addClass("offset-2");

      form.find(" .kr-box .error-owner").show();
    } else {
      form.find(" .objective_childrens_owner_id select").removeClass("is-invalid");
      form.find(" .kr-box .error-owner").hide();
    }
    return false;
  } else {
    return true;
  }
};

window.templateIcon = (icon) => {
  var src;
  if (!icon.id) {
    return icon.text;
  }
  $.ajax({
    url: `/pages/accounts/get_avatar`,
    async: false,
    method: "GET",
    data: {
      account_id: icon.id,
    },
    success: function (data) {
      src = data.url != undefined ? data.url : LetterAvatar(data["name"], "24");
    },
  });
  var $gender = $(
    '<span><img src="' +
      src +
      '" width="24" height="24" class="rounded-circle mr-2" style="object-fit: cover;" avatar="' +
      icon.text +
      '" />' +
      icon.text +
      "</span>",
  );
  return $gender;
};

window.updateProgressBar = (e) => {
  let kr, min, max, value, id, revert, list_parents, val, params;
  kr = $(e);
  min = parseInt(kr.attr("min"));
  max = parseInt(kr.attr("max"));
  value = parseInt(kr.val());
  id = parseInt(kr.attr("id"));
  revert = kr.attr("revert");

  if (revert == "true") {
    val = max - value + min;
  } else {
    val = value;
  }
  // obj parents update score
  list_parents = [];
  $(`.nested-fields[data-index="okr-${id}"]`)
    .parents(".parent_li")
    .map(function (index) {
      list_parents.push(
        $(this).find("span .nested-fields").first().data("index").replace("okr-", ""),
      );
    });
  params = get_page_need_updated();
  $.ajax({
    url: `/pages/objectives/${id}/update_progress_bar`,
    type: "PUT",
    data: {
      current_point: val,
      keyResultId: id,
      list_parents: list_parents,
      controller_name: params[0],
      user_id: params[1],
      obj_id: params[2],
      type_team_okrs: params[3],
      detail_page_id: params[4],
      year: urlParam("year"),
      quarter: urlParam("quarter"),
      annualy_normaly_choice: urlParam("annualy_normaly_choice"),
    },
    beforeSend: function () {
      xstart();
      openLoading();
    },
    success: function (data) {
      $("#modal_loading").modal("hide");
      xend();
    },
  });
};

window.openLoading = async () => {
  $("#modal_loading").modal("show");
  let index = Date.now();
  $("#modal_loading .text-loading").attr("data-index", index);
  if ($(`#modal_loading .text-loading[data-index='${index}']`).length > 0) {
    $(`#modal_loading .text-loading[data-index='${index}']`).html("Updating...");
    await asyncTimeout(3000);
    $(`#modal_loading .text-loading[data-index='${index}']`).html("Almost there...");
    await asyncTimeout(3000);
    $(`#modal_loading .text-loading[data-index='${index}']`).html("Hang tight...");
  }
};

window.asyncTimeout = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

window.checkLengthNameOkr = (e) => {
  const Length_Text = e.val().length;
  if (Length_Text > 100) {
    if (e.parent().find(".invalid-feedback").length == 1) {
      return;
    }
    if (e.attr("id") == "objective_name") {
      e.parent().append(
        `<div class="invalid-feedback d-block" style="font-size: 12.8px;">Objective description must be less than 100 characters.</div>`,
      );
    } else {
      e.parents(".nested-fields")
        .find(".error-name")
        .removeClass("d-none")
        .text("Key result description must be less than 100 characters.");
    }
  } else {
    if (e.attr("id") == "objective_name") {
      e.parent().find(".invalid-feedback").remove();
    } else {
      e.parents(".nested-fields")
        .find(".error-name")
        .addClass("d-none")
        .text("Please fill in your key result.");
      if (e.next(".invalid-feedback").length > 0) e.next(".invalid-feedback").remove();
    }
    if (e.hasClass("is-invalid")) e.removeClass("is-invalid");
  }
};
